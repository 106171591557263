import {
  Suspense,
  memo,
  useEffect,
  useMemo,
} from 'react'
import {
  includes,
  isBoolean,
  toLower,
} from 'lodash-es'
import { useCheckFeatures } from 'd2/components/CheckFeature'
import { useLocation, useNavigate } from 'd2/hooks/useRouter'
import { useUserHasHiddenPlanQuery, useUserNeedsOtpQuery } from 'd2/queries/shared'
import LoadingSpinner from 'd2/components/LoadingSpinner'
import NotFoundScreen from 'd2/loadables/NotFoundScreen'
import Suspender from 'react-suspender'
import isTestEnv from 'd2/utils/isTestEnv'
import useReturnUrl from 'd2/hooks/useReturnUrl'
import useTracking from 'd2/hooks/useTracking'
import type { SharedProps } from './types'

const SharedLayout = memo<SharedProps>(({
  anyFeature = [],
  anyFeatureUnless = [],
  children,
  Layout,
  layoutProps,
  checkHiddenPlan,
}) => {
  const { pathname } = useLocation()
  const navigate = useNavigate()
  useTracking()
  const [hasFeature, querySwitch] = useCheckFeatures(anyFeature)
  const [otpData] = useUserNeedsOtpQuery()
  const [hasFeatureUnless] = useCheckFeatures(anyFeatureUnless)
  const [hasHiddenPaymentPlan] = useUserHasHiddenPlanQuery()
  const authorized: boolean = (anyFeature.length === 0 || !!hasFeature) && (anyFeatureUnless.length === 0 || !hasFeatureUnless) && (!checkHiddenPlan || !hasHiddenPaymentPlan?.me_or_null?.has_hidden_payment_plan)
  const returnUrl = useReturnUrl()

  useEffect(() => {
    if (otpData && includes(toLower(otpData.error?.message), 'recaptcha') && !includes(pathname, 'captcha') && pathname !== '/d2/sign_in') {
      navigate(`/d2/captcha?return_url=${encodeURIComponent(pathname)}`)
    }
    if (!isTestEnv && otpData?.data?.user_needs_otp && !includes(pathname, 'otp') && pathname !== '/d2/tdd' && !includes(pathname, 'captcha')) {
      navigate(`/d2/otp?return_url=${encodeURIComponent(pathname)}`)
    }
  }, [
    navigate,
    otpData,
    pathname,
    returnUrl,
  ])

  const innerFallback = useMemo(() => <LoadingSpinner />, [])

  return (
    <Suspense fallback={querySwitch}>
      { isBoolean(hasFeature) && isBoolean(hasFeatureUnless)
        ? Layout
          ? <Layout {...layoutProps}>
            <Suspense fallback={innerFallback}>
              { authorized ? children : <NotFoundScreen /> }
            </Suspense>
          </Layout>
          : <Suspense fallback={innerFallback}>
            { authorized
              ? <>
                { children }
              </>
              : <NotFoundScreen /> }
          </Suspense>
        : <>
          { Layout
            ? <Layout {...layoutProps}>
              <Suspense fallback={innerFallback}>
                { /* While query is fetching, assume the user may have the feature, and begin concurrent rendering of the screen while suspending */ }
                { /* If this is a common pattern, consider making a reusable component to encapsulate this logic */ }
                <Suspender />
                { children }
              </Suspense>
            </Layout>
            : <Suspense fallback={innerFallback}>
              { children }
            </Suspense> }
        </> }
    </Suspense>
  )
})

SharedLayout.displayName = 'SharedLayout'

export default SharedLayout
