/* eslint-disable @typescript-eslint/no-unnecessary-condition */
// TODO: d2ify
import { ApolloClient } from '@apollo/client/core'
import { ApolloLink } from '@apollo/client'
import { BatchHttpLink } from '@apollo/client/link/batch-http'
import {
  CODE_FORBIDDEN,
  CODE_UNAUTHORIZED,
  CODE_UNAVAILABLE,
} from 'd2/constants/status_codes'
import { InMemoryCache } from '@apollo/client/cache'
import { RetryLink } from '@apollo/client/link/retry'
import {
  compact,
  includes,
  isEqual,
  some,
} from 'lodash-es'
import {
  enableLogging,
  log,
  logWarn,
} from '../environment'
import { onError } from '@apollo/client/link/error'
import ApolloLinkEvents from '../ApolloLinkEvents'
// @ts-expect-error must convert module to ts
import apolloLogger from 'apollo-link-logger'
import cacheRedirects from 'd2/queries/cacheRedirects'
import eventEmitter from 'event-emitter'
import possibleTypes from 'd2/queries/possibleTypes.json'
import raygun from 'd2/utils/raygun'
import type { ApolloClient as ApolloClientType, MutationError } from 'd2/types'

export type IncludesMutationError = (
  b: MutationError[],
  a: {
    keys: string[],
    messages: string[]
  }
) => boolean

export const includesMutationError: IncludesMutationError = (errors, { keys, messages }) =>
  some(errors, ({
    key: errorKey,
    messages: errorMessages,
  }) => some(keys, (key) => isEqual(errorKey, key)) && some(messages, (message) => includes(errorMessages, message)))

export const createApolloClient = (dataLink: ApolloLink): ApolloClientType<any> => {
  const cache = new InMemoryCache({
    addTypename: true,
    // @ts-expect-error TODO: Update cacheRedirects to new apollo version
    cacheRedirects,
    possibleTypes,
  })

  const emitter = eventEmitter({})
  const eventsLink = new ApolloLinkEvents(emitter)

  // Mostly copied from https://github.com/blackxored/apollo-link-logger/blob/v1.2.3/src/index.js#L6
  const customLoggerLink = new ApolloLink((operation, forward) => {
    if (!forward) return null

    const startTime = Date.now()

    return forward(operation).map((result) => {
      // @ts-expect-error (auto-migrated from flow FixMe)[prop-missing] - Error when upgrading to flow 0.142.0
      const operationType = operation.query.definitions[0].operation
      const elapsed = Date.now() - startTime

      const group = [operationType, operation, elapsed]

      log('APOLLO OPERATION START', ...group)

      log('INIT', operation)
      log('RESULT', result)

      log('APOLLO OPERATION END', ...group)
      return result
    })
  })

  const logger = enableLogging() ? [apolloLogger] : [customLoggerLink]

  const errorLink = onError(({ graphQLErrors, networkError, operation: { operationName, variables }, response }) => {
    if (includes(['NetworkError when attempting to fetch resource.', 'Failed to fetch'], networkError?.message)) return

    raygun('send', {
      customData: {
        graphQLErrors,
        networkError: {
          // @ts-expect-error Property 'bodyText' does not exist on Error
          bodyText: networkError?.bodyText,
          message: networkError?.message,
          // @ts-expect-error Property 'parseError' does not exist on Error
          parseError: networkError?.parseError,
          // @ts-expect-error Property 'response' does not exist on Error
          response: networkError?.response,
          // @ts-expect-error Property 'result' does not exist on Error
          result: networkError?.result,
          // @ts-expect-error Property 'statusCode' does not exist on Error
          statusCode: networkError?.statusCode,
        },
        operationName,
        response,
        variables,
      },
      error: `GraphQL Error: ${operationName}. ${graphQLErrors?.[0]?.message ?? 'Check the "Custom" tab for details.'}`,
      tags: compact([
        'GraphQL',
        // @ts-expect-error Property 'statusCode' does not exist on Error
        networkError?.statusCode ? `HTTP${networkError.statusCode}` : null,
        includes(['NetworkError when attempting to fetch resource.', 'Failed to fetch'], networkError?.message) ? 'failed_to_fetch' : null,
        networkError?.message === 'The Internet connection appears to be offline.' ? 'client_offline' : null,
      ]),
    })

    if (graphQLErrors) {
      logWarn(`GraphQLErrors for query ${operationName}: \n${JSON.stringify(graphQLErrors)}`)
    }

    if (networkError) {
      // @ts-expect-error Property 'statusCode' does not exist on type 'Error | ServerError | ServerParseError'. Property 'statusCode' does not exist on type 'Error'.ts(2339)
      switch (networkError.statusCode) {
      case CODE_UNAUTHORIZED: {
        logWarn(`HTTP status: 401 Unauthorized in apollo response for query ${operationName}. Usually indicates no active session and no access to the requested resource.`)
        // When a query gives a 401, take them to the home screen because most likely they were logged out
        // We removed the redirect because of infinite loops due to MFA otp redirects.
        // d2VisitD1('/') // It's probably best to do a full page refresh using `d2VisitD1` here.
        // TODO: Consider displaying a message to the user in a snackbar or something?
        break
      }
      case CODE_FORBIDDEN: {
        logWarn(`HTTP status: 403 Forbidden in apollo response for query ${operationName}. Usually indicates valid active session but no access to the requested resource.`)
        // When a query gives a 403, the user has an active session, but does NOT have access to the requested resource(s).
        // We removed the redirect because of infinite loops due to MFA otp redirects.
        // d2VisitD1('/') // It's probably best to do a full page refresh using `d2VisitD1` here.
        // TODO: Consider displaying a message to the user in a snackbar or something?
        break
      }
      case CODE_UNAVAILABLE: {
        logWarn('HTTP status: 503 Unavailable in apollo response')
        // TODO: Consider displaying a message to the user in a snackbar or something?
        break
      }
      default: {
        break
      }
      }
    }
  })

  // TODO: Can a query opt-out of retrying?
  const retryLink = new RetryLink()

  const link = ApolloLink.from([
    ...logger,
    eventsLink,
    errorLink,
    retryLink,
    dataLink,
  ])
  const client = new ApolloClient({
    cache,
    connectToDevTools: true, // TODO: We want this?
    link,
    queryDeduplication: true, // TODO: We want this?
  })
  // @ts-expect-error Property 'events' does not exist on type 'ApolloClient<NormalizedCacheObject>'.ts(2339)
  client.events = emitter // from the client you can't just get an individual link easily, so just put the event emitter on the client
  return client
}

const uri = '/api/graphql/v1?explorer=false'

let defaultClient: ApolloClient<any> | undefined

function apollo () {
  if (defaultClient) return defaultClient
  defaultClient = createApolloClient(new BatchHttpLink({
    credentials: 'same-origin',
    uri,
  }))

  return defaultClient
}

export default apollo
