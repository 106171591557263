/* eslint-disable @typescript-eslint/prefer-nullish-coalescing */
import { forEach } from 'lodash-es'

/*
Helper functions for the current app environment.
*/

export const isDevelopment = (): boolean => typeof window !== 'undefined'
  // @ts-expect-error window.VYDIA_SETTINGS
  && window.VYDIA_SETTINGS?.ENVIRONMENT === 'development'

export const isTest = (): boolean => typeof window === 'undefined'
  // @ts-expect-error window.VYDIA_SETTINGS
  || !window.VYDIA_SETTINGS || !window.VYDIA_SETTINGS.ENVIRONMENT || window.VYDIA_SETTINGS.ENVIRONMENT === 'test'

export const isProduction = (): boolean => !isTest() && !isDevelopment()

/*
If true, console logging for graphql, redux, etc should be enabled.
With a secret code snippet you paste into your console, you can activate logging on production. Helpful for tracking bugs.
To activate, open your web browser console and enter:

sessionStorage.setItem("log",true)

Then refresh your browser and it will work until you close the tab. If this proves useful, you could make it easier to active via a query string param or something.
*/
export const enableLogging = (): boolean => !!(process.env.TDD_LOG_HIGH || isDevelopment()
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  || window.sessionStorage?.getItem('log'))

export const log = (...args: any[]): void => {
  if (enableLogging()) {
    // eslint-disable-next-line no-console
    console.log(...args)
  } else if (typeof window !== 'undefined'
  // @ts-expect-error window.FS
  && window.FS?.log) {
    forEach(args, (arg) => {
      // @ts-expect-error window.FS
      window.FS
        .log('log', arg)
    })
  }
}

export const logWarn = (...args: any[]): void => {
  if (enableLogging()) {
    // eslint-disable-next-line no-console
    console.warn(...args)
  } else if (typeof window !== 'undefined'
  // @ts-expect-error window.FS
  && window.FS?.log) {
    forEach(args, (arg) => {
      // @ts-expect-error window.FS
      window.FS
        .log('warn', arg)
    })
  }
}

export const logError = (...args: any[]): void => {
  if (enableLogging()) {
    // eslint-disable-next-line no-console
    console.error(...args)
  } else if (typeof window !== 'undefined'
  // @ts-expect-error window.FS
  && window.FS?.log) {
    forEach(args, (arg) => {
      // @ts-expect-error window.FS
      window.FS
        .log('error', arg)
    })
  }
}

// For redux-logger
export const consoleReplacement = {
  error: logError,
  log,
  warn: logWarn,
}
