/* eslint-disable consistent-default-export-name/default-export-match-filename */
// TODO: enable react-memo/require-usememo and memoize routes children
/* eslint-disable react-memo/require-usememo */
/*
The primary router for D2 screens.
*/
import { Features } from 'd2/queries'
import {
  Navigate,
  Route,
  Routes,
} from 'react-router-dom'
import { SPA_ROOT } from 'd2/constants'
import { constant } from 'lodash-es'
import { memo } from 'react'
import Account from 'd2/loadables/Account'
import AccountActivity from 'd2/loadables/AccountActivity'
import AccountDeletion from 'd2/loadables/AccountDeletion'
import AccountDeletionWarning from 'd2/loadables/AccountDeletionWarning'
import AccountSignedAgreement from 'd2/loadables/AccountSignedAgreement'
import AdvancesIndex from 'd2/loadables/AdvancesIndex'
import AlbumCreation from 'd2/loadables/AlbumCreation'
import AlbumDetails from 'd2/loadables/AlbumDetails'
import AlbumDetailsPublishSuccess from 'd2/loadables/AlbumDetailsPublishSuccess'
import AlbumDetailsPublishSuccessV2 from 'd2/loadables/AlbumDetailsPublishSuccessV2'
import ArtistDetails from 'd2/loadables/ArtistDetails'
import ArtistsIndex from 'd2/loadables/ArtistsIndex'
import AudioDestinationNews from 'd2/loadables/AudioDestinationNews'
import CaptchaScreen from 'd2/loadables/CaptchaScreen'
import Content from 'd2/loadables/Content'
import ContentLicenseAgreementNotice from 'd2/loadables/ContentLicenseAgreementNotice'
import D2DevLayout from './D2DevLayout'
import DPAScreen from 'd2/loadables/DPAScreen'
import DataDeleteRequest from 'd2/loadables/DataDeleteRequest'
import Distribution from 'd2/loadables/Distribution'
import EarningsStatementIngestion from 'd2/loadables/EarningsStatementIngestion'
import ExamplesScreen from 'd2/loadables/ExamplesScreen' // eslint-disable-line @typescript-eslint/no-restricted-imports
import Faq from 'd2/loadables/Faq'
import ForLabelHub from 'd2/components/ForLabelHub'
import Insights from 'd2/loadables/Insights'
import MerchItemEdit from 'd2/loadables/MerchItemEdit'
import NavigateDynamic from 'd2/components/NavigateDynamic'
import NavigatePathRewrite from 'd2/components/NavigatePathRewrite'
import NeedsAgreement from 'd2/components/NeedsAgreement'
import NewAdvance from 'd2/loadables/NewAdvance'
import NotFoundScreen from 'd2/loadables/NotFoundScreen'
import OAuthReturn from 'd2/loadables/OAuthReturn'
import OneTimePassword from 'd2/loadables/OneTimePassword'
import OrganizationBulkInviteUsers from 'd2/loadables/OrganizationBulkInviteUsers'
import OrganizationDetails from 'd2/loadables/OrganizationDetails'
import OrganizationEditUser from 'd2/loadables/OrganizationEditUser'
import OrganizationInviteUser from 'd2/loadables/OrganizationInviteUser'
import PartialLayout from './PartialLayout'
import PasswordConfirmation from 'd2/loadables/PasswordConfirmation'
import PayeeDetails from 'd2/loadables/PayeeDetails'
import PayeeInviteExpired from 'd2/loadables/PayeeInviteExpired'
import PayeeSignUp from 'd2/loadables/PayeeSignUp'
import PlanDowngrade from 'd2/loadables/PlanDowngrade'
import PrivacyPolicyScreen from 'd2/loadables/PrivacyPolicyScreen'
import PublishAdditionalServices from 'd2/loadables/PublishAdditionalServices'
import PublishNetworkSelect from 'd2/loadables/PublishNetworkSelect'
import PublishReview from 'd2/loadables/PublishReview'
import RecoupmentCreation from 'd2/loadables/RecoupmentCreation'
import ReleaseCalendar from 'd2/loadables/ReleaseCalendar'
import ResetPassword from 'd2/loadables/ResetPassword'
import RevenuePrediction from 'd2/loadables/RevenuePrediction'
import RoyaltyCenter from 'd2/loadables/RoyaltyCenter'
import ScreenLayout from './ScreenLayout'
import ScrollToTopOnChangePath from 'd2/components/ScrollToTopOnChangePath'
import SecuritySettings from 'd2/partials/SecuritySettings'
import ShareAlbum from 'd2/loadables/ShareAlbum'
import SignIn from 'd2/loadables/SignIn'
import SignatureCreation from 'd2/loadables/SignatureCreation'
import SpotifyForArtists from 'd2/loadables/SpotifyForArtists'
import Statements from 'd2/loadables/Statements'
import StorageConsumptionScreen from 'd2/loadables/StorageConsumptionScreen'
import StorageSuccessScreen from 'd2/loadables/StorageSuccessScreen'
import StorageTierModalWrapper from 'd2/partials/StorageTierModalWrapper'
import TDD from 'd2/loadables/TDD'
import TaxSubmission from 'd2/loadables/TaxSubmission'
import Terms from 'd2/loadables/Terms'
import TermsAndConditions from 'd2/loadables/TermsAndConditions'
import TipaltiFaq from 'd2/loadables/TipaltiFaq'
import TipaltiSignup from 'd2/loadables/TipaltiSignup'
import Ucla from 'd2/loadables/Ucla'
import Upload from 'd2/loadables/Upload'
import VerifyEmail from 'd2/loadables/VerifyEmail'
import WidgetDashboard from 'd2/loadables/WidgetDashboard'
import isTestEnv from 'd2/utils/isTestEnv'

const NO_STORAGE_SPACE_BANNER_LAYOUT_PROPS = {
  storageSpaceBanner: constant(null),
}

const AppRoutes = memo<EO>(() => (<>
  <ScrollToTopOnChangePath />
  <Routes>
    <Route element={<PartialLayout />}>
      <Route
        element={<TaxSubmission />}
        path="/d2/tax_submission"
      />
      <Route
        element={<OAuthReturn />}
        path="/d2/oauth_return/:param"
      />
      <Route
        element={<SignIn />}
        path="/d2/sign_in"
      />
      <Route
        element={<ResetPassword />}
        path="/d2/reset_password"
      />
      <Route
        element={<OneTimePassword />}
        path="/d2/otp"
      />
      { isTestEnv && <Route
        element={<SecuritySettings />}
        path="/d2/account/security"
      /> }
      <Route path="/d2/payee">
        <Route
          element={<PayeeSignUp />}
          path='signup'
        />
        <Route
          element={<PayeeInviteExpired />}
          path='expired'
        />
      </Route>
      <Route
        element={<ShareAlbum />}
        path="/d2/share/:albumEncoded"
      />
      <Route
        element={<CaptchaScreen />}
        path="/d2/captcha"
      />
      <Route
        element={<TipaltiSignup />}
        path="/d2/tipalti/signup"
      />
    </Route>
    <Route
      element={<ScreenLayout
        layoutProps={NO_STORAGE_SPACE_BANNER_LAYOUT_PROPS}
      />}
      path="/d2/terms_and_conditions"
    >
      <Route
        element={<TermsAndConditions />}
        index
      />
    </Route>
    <Route
      element={<ScreenLayout />}
      path="/d2/ecla"
    >
      <Route
        element={<Ucla />}
        index
      />
    </Route>
    <Route
      path="/d2/ucla"
    >
      <Route
        element={<Navigate to="/d2/ecla" />}
        index
      />
    </Route>
    <Route
      element={<ScreenLayout anyFeature={[Features.d2_spotify_for_artists]} />}
      path="/d2/spotify-for-artists"
    >
      <Route
        element={<SpotifyForArtists />}
        index
      />
    </Route>
    <Route
      element={<ScreenLayout anyFeature={[Features.d2_creators]} />}
      path="/d2/artists"
    >
      <Route
        element={<ArtistsIndex />}
        index
      />
    </Route>

    { /* MAIN D2 ROUTES */ }
    <Route
      element={<ScreenLayout />}
      path={SPA_ROOT}
    >
      <Route
        element={<Navigate to="/d2/dashboard" />}
        index
      />
      <Route path='account'>
        <Route
          element={<Account />}
          path=':section'
        />
        <Route
          element={<NeedsAgreement forEcla>
            <AccountSignedAgreement />
          </NeedsAgreement>}
          path='signed_agreements'
        />
        <Route
          element={<Navigate to="/d2/account/info" />}
          index
        />
      </Route>
      <Route
        element={<AccountActivity />}
        path='account_activity'
      />
      <Route
        element={<NeedsAgreement forAddressChange>
          <WidgetDashboard />
        </NeedsAgreement>}
        path='dashboard'
      />
      <Route
        element={<PasswordConfirmation>
          <AccountDeletion />
        </PasswordConfirmation>}
        path='delete_account'
      />
      <Route
        element={<PasswordConfirmation>
          <AccountDeletionWarning />
        </PasswordConfirmation>}
        path='account_deletion_warning'
      />
      <Route
        element={<ForLabelHub>
          <PrivacyPolicyScreen />
        </ForLabelHub>}
        path='privacy_policy'
      />
      <Route
        element={<ForLabelHub>
          <Terms />
        </ForLabelHub>}
        path='terms'
      />
      <Route
        element={
          <ForLabelHub>
            <DPAScreen />
          </ForLabelHub>
        }
        path='dpa'
      />
      <Route
        element={<VerifyEmail />}
        path='verify_email'
      />
      <Route path='upload'>
        <Route
          element={
            // TODO: NeedsAgreement and CheckFeature each make a separate network request sequentially. Ideally we could make them in parallel to save time.
            <NeedsAgreement
              forEcla
              forOwnerEcla
            >
              <StorageTierModalWrapper />
              <Upload type='audio' />
            </NeedsAgreement>
          }
          path='audio'
        />
        <Route
          element={
            // TODO: NeedsAgreement and CheckFeature each make a separate network request sequentially. Ideally we could make them in parallel to save time.
            <NeedsAgreement
              forEcla
              forOwnerEcla
            >
              <StorageTierModalWrapper />
              <Upload type='video' />
            </NeedsAgreement>
          }
          path='video'
        />
        <Route
          element={
            // TODO: NeedsAgreement and CheckFeature each make a separate network request sequentially. Ideally we could make them in parallel to save time.
            <NeedsAgreement
              forEcla
              forOwnerEcla
            >
              <StorageTierModalWrapper />
              <Upload type='composition' />
            </NeedsAgreement>
          }
          path='composition'
        />
      </Route>

      { /* LABEL */ }
      <Route path='label'>
        <Route
          element={<Navigate to="users" />}
          index
        />
        <Route
          element={<RecoupmentCreation />}
          path='advances/new'
        />
        <Route
          element={
            <NeedsAgreement
              forEcla
              forOwnerEcla
            >
              <OrganizationInviteUser />
            </NeedsAgreement>
          }
          path='users/new'
        />
        <Route
          element={<OrganizationEditUser />}
          path="users/:userId/edit"
        />
        <Route
          element={
            <NeedsAgreement forEcla>
              <OrganizationBulkInviteUsers />
            </NeedsAgreement>
          }
          path='users/bulk_invite'
        />
        <Route
          element={<OrganizationDetails />}
          path=':section'
        />
      </Route>

      { /* ARTISTS */ }
      <Route path='artists'>
        <Route
          element={<ArtistDetails />}
          path=':id'
        >
          <Route
            element={<ArtistDetails />}
            path=':section'
          />
        </Route>
      </Route>

      { /* ALBUMS */ }
      <Route path='albums'>
        <Route path=':id'>
          <Route
            element={<AlbumDetails />}
            index
          />
          <Route
            element={<AlbumDetailsPublishSuccess />}
            path='publish/audio/success'
          />
          <Route
            element={<AlbumDetailsPublishSuccessV2 />}
            path='publish/audio/success/releases/:releaseId'
          />
          <Route
            element={<AlbumDetails />}
            path=':section'
          />
        </Route>
        <Route
          element={
            <NeedsAgreement
              forEcla
              forOwnerEcla
            >
              <AlbumCreation />
            </NeedsAgreement>
          }
          path='new'
        />
      </Route>

      { /* PUBLISH */ }
      <Route path=':contentType/:id/publish'>
        <Route
          element={<NeedsAgreement forOwnerEcla>
            <PublishNetworkSelect />
          </NeedsAgreement>}
          path=':networkType'
        />
        <Route
          element={<PublishAdditionalServices />}
          path=':networkType/additional_services'
        />
        <Route
          element={<PublishReview />}
          path=':networkType/review'
        />
      </Route>

      { /* INSIGHTS */ }
      <Route path='insights'>
        <Route
          element={<Navigate to="/d2/insights/artist_analytics" />}
          index
        />
        <Route
          element={<Insights />}
          path=':section'
        >
          <Route
            element={<Insights />}
            index
          />
          <Route
            element={<Insights />}
            path=':id'
          />
          <Route path='users'>
            <Route
              element={<Insights />}
              path=':userId'
            >
              <Route path='artists'>
                <Route
                  element={<Insights />}
                  path=':artistId'
                >
                  <Route path='albums'>
                    <Route
                      element={<Insights />}
                      path=':albumId'
                    />
                  </Route>
                  <Route path='medias'>
                    <Route
                      element={<Insights />}
                      path=':mediaId'
                    />
                  </Route>
                </Route>
              </Route>
            </Route>
          </Route>
          <Route path='artists'>
            <Route
              element={<Insights />}
              path=':artistId'
            >
              <Route path='albums'>
                <Route
                  element={<Insights />}
                  path=':albumId'
                />
              </Route>
              <Route path='medias'>
                <Route
                  element={<Insights />}
                  path=':mediaId'
                />
              </Route>
            </Route>
          </Route>
        </Route>
      </Route>

      { /* MERCH */ }
      <Route path='merch'>
        { /* TODO: MerchIndex
        <Route
          element={<MerchIndex />}
          index
        /> */ }
        { /* TODO: Eventually allow user to create a new merch item directly instead of thru a release?
        <Route
          element={<NewAdvance />}
          path='new'
        /> */ }
        { /* TODO: Authorization / feature flag? */ }
        <Route
          element={<MerchItemEdit />}
          path=':id/edit/*'
        />
        <Route
          // NavigateDynamic is used to navigate to a dynamic route where the param is interpolated into the `to` path.
          element={<NavigateDynamic to="/d2/merch/:id/edit" />}
          path=':id'
        />
      </Route>

      { /* RELEASE CALENDAR */ }
      <Route path='release_calendar'>
        <Route
          element={<ReleaseCalendar />}
          index
        />
        <Route path=':section'>
          <Route
            element={<ReleaseCalendar />}
            index
          />
          <Route
            element={<ReleaseCalendar />}
            path='users/:id'
          >
            <Route
              element={<ReleaseCalendar />}
              path='artists/:artistId'
            >
              <Route
                element={<ReleaseCalendar />}
                path='medias/:mediaId'
              />
              <Route
                element={<ReleaseCalendar />}
                path='albums/:albumId'
              />
            </Route>
          </Route>
          <Route
            element={<ReleaseCalendar />}
            path='artists/:artistId'
          >
            <Route
              element={<ReleaseCalendar />}
              path='medias/:mediaId'
            />
            <Route
              element={<ReleaseCalendar />}
              path='albums/:albumId'
            />
          </Route>
        </Route>
      </Route>

      { /* FILES */ }
      <Route path='files'>
        <Route
          element={<Navigate to="/d2/files/audio/albums" />}
          index
        />
        <Route
          element={<Navigate to="/d2/files/audio/albums" />}
          path='audio'
        />
        <Route
          element={<Content />}
          path=':paramOne'
        >
          <Route
            element={<Content />}
            path=':paramTwo'
          />
        </Route>
      </Route>

      { /* ROYALTY */ }
      <Route
        element={<PayeeDetails />}
        path='royalty/payees/:id/:section'
      />
      <Route
        element={<RoyaltyCenter />}
        path='royalty/*'
      />

      <Route
        element={<StorageConsumptionScreen />}
        path='storage'
      />
      <Route
        element={<PasswordConfirmation>
          <DataDeleteRequest />
        </PasswordConfirmation>}
        path='data_delete_request'
      />
      <Route
        element={<Distribution />}
        path='distribution'
      />
      <Route
        element={<ContentLicenseAgreementNotice />}
        path='notice'
      />
      { /* Statements */ }
      <Route
        path='statements'
      >
        <Route
          element={<Statements />}
          index
        />
        <Route
          element={<EarningsStatementIngestion />}
          path='ingestion'
        />
      </Route>
      <Route path='faq'>
        <Route
          element={<Faq />}
          index
        />
        <Route
          element={<TipaltiFaq />}
          path='tipalti'
        />
      </Route>
      <Route
        element={<AudioDestinationNews />}
        path='partner-destination-news'
      />
      <Route
        path='admin/revenue_prediction'
      >
        <Route
          element={<RevenuePrediction />}
          index
        />
        <Route
          element={<RevenuePrediction />}
          path='orgs/:orgId'
        />
        <Route
          element={<RevenuePrediction />}
          path='users/:userId'
        />
        <Route
          element={<RevenuePrediction />}
          path='medias/:mediaId'
        />
      </Route>
      <Route
        element={<SignatureCreation />}
        path='admin/signature'
      />
      <Route
        element={<NavigatePathRewrite
          rewrite="advances"
          to="deals"
        />}
        path='advances/*'
      />
      <Route
        path='deals'
      >
        <Route
          element={<AdvancesIndex />}
          index
        />
        <Route
          element={<NewAdvance />}
          path='new'
        />
        <Route
          element={<NewAdvance />}
          path=':dealId/edit' // DEAL_ID_PARAM
        />
        <Route
          // NavigateDynamic is used to navigate to a dynamic route where the param is interpolated into the `to` path.
          element={<NavigateDynamic to="/d2/deals/:dealId/edit" />}
          path=':dealId'
        />
      </Route>
      <Route
        element={<NotFoundScreen />}
        path='404'
      />
      <Route
        element={<NotFoundScreen />}
        path='*'
      />
    </Route>

    { /* STORAGE */ }
    <Route
      element={<ScreenLayout checkHiddenPlan />}
      path="/d2/storage"
    >
      <Route
        element={<StorageConsumptionScreen />}
        path='plans'
      />
      <Route
        element={<StorageConsumptionScreen />}
        path='users/:userId'
      >
        <Route
          element={<StorageConsumptionScreen />}
          path='artists/:artistId'
        >
          <Route
            element={<StorageConsumptionScreen />}
            path='albums/:albumId'
          />
        </Route>
      </Route>
      <Route
        element={<StorageConsumptionScreen />}
        path='artists/:artistId'
      >
        <Route
          element={<StorageConsumptionScreen />}
          path='albums/:albumId'
        />
      </Route>
      <Route
        element={<StorageSuccessScreen mode='upgrade' />}
        path='upgrade/success'
      />
      <Route
        element={<StorageSuccessScreen mode='downgrade' />}
        path='downgrade/success'
      />
      <Route
        element={<PlanDowngrade />}
        path='plans/downgrade'
      />
    </Route>

    <Route element={<D2DevLayout />}>
      <Route path='d2/examples'>
        <Route
          element={<ExamplesScreen />}
          index
        />
        <Route
          element={<ExamplesScreen />}
          path=':componentName/:variantName'
        />
        <Route
          element={<ExamplesScreen />}
          path=':componentName/*'
        />
        <Route
          element={<ExamplesScreen />}
          path='*'
        />
      </Route>
    </Route>

    <Route
      element={<D2DevLayout />}
      path='/d2/tdd'
    >
      <Route
        // eslint-disable-next-line react/jsx-pascal-case
        element={<TDD />}
        index
      />
    </Route>
    <Route
      element={<ScreenLayout />}
      path='/d2/tdd/blank_screen_route'
    >
      <Route
        element={<>
          TDD Blank Screen Route
        </>}
        index
      />
    </Route>
    <Route
      element={<PartialLayout />}
      path='/d2/tdd/blank_partial_route'
    >
      <Route
        element={<>
          TDD Blank Partial Route
        </>}
        index
      />
    </Route>

    { isTestEnv && <Route
      element={<Navigate to="/d2/tdd/blank_partial_route" />}
      path='/' // TDD always `start()` on '/' root path, not even `/d2/` path. This is a hack workaround to render a blank screen instead of a ScreenRoute, to avoid rendering MainNavBar before any mocks are in place.
    /> }
    { /* TODO: Fix this */ }
    { /* <Route
      element={
        // Prevent turbolinks 404
        typeof window !== 'undefined' && window.location.reload()
      }
      path='*'
    /> */ }
  </Routes>
</>))

AppRoutes.displayName = 'AppRoutes'

export default AppRoutes
