/* eslint-disable @typescript-eslint/no-use-before-define */
import { DateTime } from 'luxon'
import { d } from 'd2/utils'
import {
  includes,
  isArray,
  isBoolean,
  isEmpty,
  isNil,
  isNumber,
  isObjectLike,
  mapValues,
  replace,
  toString,
} from 'lodash-es'
import qs from 'qs'

// ts-prune-ignore-next - Used in D1 JS
export function s3Url (s3Path: string): string {
  // Unable to handle this well without window without some env var refactor. Hopefully this is not invoked by anything that we need to use in Next.js
  // @ts-expect-error window.VYDIA_SETTINGS
  if (window.VYDIA_SETTINGS?.S3_CDN_URL) {
    // @ts-expect-error window.VYDIA_SETTINGS
    return `${window.VYDIA_SETTINGS.S3_CDN_URL}/${s3Path}`
  }
  // @ts-expect-error window.s3_bucket
  return `https://s3.amazonaws.com/${window.s3_bucket}/${s3Path}`
}

export function assetUri (localPath: string): string {
  // @ts-expect-error window.VYDIA_SETTINGS
  return ((typeof window !== 'undefined' && window.VYDIA_SETTINGS?.CDN_URL) || '') + localPath
}

export function assetResourceUri (localPath: string): string {
  return assetUri(`/resources/${localPath}`)
}

export function buildUri (path: string, params: any): string {
  if (isNil(params) || isEmpty(params)) return path
  const joinWith: string = includes(path, '?') ? '&' : '?'
  return `${path}${joinWith}${buildNestedQuery(params)}`
}

export function buildNestedQuery (params: AO): string {
  const formattedParams = mapValues(params, (value) => coerceQueryParam(value))
  return qs.stringify(formattedParams, {
    // 'indices' seems to be required for nested arrays & objects. Otherwise, brackets will cause the objects to merge arrays
    // e.g. with 'indices' this works:
    // inputs[advances][0][amountDollars]=250000&inputs[advances][0][month]=0&inputs[advances][1][amountDollars]=100000&inputs[advances][1][month]=4
    // e.g. with 'brackets' this doesn't work:
    // inputs[advances][][amountDollars]=250000&inputs[advances][][month]=0&inputs[advances][][amountDollars]=100000&inputs[advances][][month]=4
    arrayFormat: 'indices',
    encode: false,
  })
}

export function parseNestedQuery (search: string): any {
  const queryString = replace(search, /^\?/, '')
  return qs.parse(queryString)
}

function coerceQueryParam (param: any): (AO | string[] | string)[] | AO | string[] | string {
  if (isBoolean(param) || isNumber(param)) {
    return toString(param)
  } else if (isArray(param)) {
    return param.map((value) => coerceQueryParam(value))
  } else if (isObjectLike(param)) {
    return mapValues(param, (value) => coerceQueryParam(value))
  }

  return param
}

export function artistDetailsSectionUrl (tabName: string, id: string): string {
  return `/d2/artists/${id}/${tabName}`
}

export function fileDetailsSectionUrl (tabName: string, id: string): string {
  return `/d2/files/${id}/${tabName}`
}

export function albumDetailsSectionUrl (tabName: string, id: string, queryParams?: string): string {
  return `/d2/albums/${id}/${tabName}${queryParams ?? ''}`
}

// TODO: Flowtype all these `section` "strings" as enum/union types if possible.
export function insightsSectionUrl (section?: string | null): string {
  return section ? `/d2/insights/${section}` : '/d2/insights'
}

// Takes an array of values and returns a string which is the concatenation of all the strings in the array, stopping at the first falsey element.
// See: d2/src/utils/__tests__/Routes.tsx
export function makePath (...segments: (string | null | undefined)[]) {
  let url = ''
  for (const segment of segments) {
    if (!segment) return url
    url += segment
  }

  return url
}

export function insightsLabelUrl (
  {
    albumId,
    artistId,
    endDate,
    mediaId,
    section,
    startDate,
    userId,
  }: {
    albumId?: string | null,
    artistId?: string | null,
    endDate?: string | null,
    mediaId?: string | null,
    section?: string | null,
    startDate?: string | null,
    userId?: string | null
  },
): string {
  const path = makePath(
    userId && `/users/${userId}`,
    artistId && `/artists/${artistId}`,
    albumId ? `/albums/${albumId}` : mediaId ? `/medias/${mediaId}` : undefined,
  )

  let url = `${insightsSectionUrl(section)}${path}`
  url = endDate ? `${url}?end_date=${endDate}` : url
  url = startDate ? `${url}&start_date=${startDate}` : url
  return url
}

export function insightsUserUrl (
  {
    albumId,
    artistId,
    endDate,
    mediaId,
    section,
    startDate,
  }: {
    albumId?: string | null,
    artistId?: string | null,
    endDate?: string | null,
    mediaId?: string | null,
    section?: string | null,
    startDate?: string | null
  },
): string {
  const path = makePath(
    artistId && `/artists/${artistId}`,
    albumId ? `/albums/${albumId}` : mediaId ? `/medias/${mediaId}` : undefined,
  )
  let url = `${insightsSectionUrl(section)}${path}`
  url = endDate ? `${url}?end_date=${endDate}` : url
  url = startDate ? `${url}&start_date=${startDate}` : url
  return url
}

export function releaseCalendarSectionUrl (section?: string | null): string {
  return section ? `/d2/release_calendar/${section}` : '/d2/release_calendar'
}

export function releaseCalendarLabelUrl (
  {
    albumId,
    artistId,
    mediaId,
    section,
    userId,
  }: {
    albumId?: string | null,
    artistId?: string | null,
    mediaId?: string | null,
    section?: string | null,
    userId?: string | null
  },
): string {
  const path = makePath(
    userId && `/users/${userId}`,
    artistId && `/artists/${artistId}`,
    albumId && `/albums/${albumId}`,
    mediaId && `/medias/${mediaId}`,
  )
  return `${releaseCalendarSectionUrl(section)}${path}`
}

export function releaseCalendarUserUrl (
  {
    albumId,
    artistId,
    mediaId,
    section,
  }: {
    albumId?: string | null,
    artistId?: string | null,
    mediaId?: string | null,
    section?: string | null
  },
): string {
  const path = makePath(
    artistId && `/artists/${artistId}`,
    albumId && `/albums/${albumId}`,
    mediaId && `/medias/${mediaId}`,
  )
  return `${releaseCalendarSectionUrl(section)}${path}`
}

export function storageSectionUrl (section?: string | null): string {
  return section ? `/d2/storage/${section}` : '/d2/storage'
}

export function storageLabelUrl (
  {
    albumId,
    artistId,
    section,
    userId,
  }: {
    albumId?: string | null,
    artistId?: string | null,
    section?: string | null,
    userId?: string | null
  },
): string {
  const path = makePath(
    userId && `/users/${userId}`,
    artistId && `/artists/${artistId}`,
    albumId && `/albums/${albumId}`,
  )
  return `${storageSectionUrl(section)}${path}`
}

export function storageUserUrl (
  {
    albumId,
    artistId,
    section,
  }: {
    albumId?: string | null,
    artistId?: string | null,
    section?: string | null
  },
): string {
  const path = makePath(
    artistId && `/artists/${artistId}`,
    albumId && `/albums/${albumId}`,
  )
  return `${storageSectionUrl(section)}${path}`
}

export function audioAnalyticsUrl (
  {
    albumId,
    artistId,
    endDate,
    isLabel,
    mediaId,
    startDate,
    userId,
  }: {
    albumId?: string | null,
    artistId?: string | null,
    endDate?: string | null,
    isLabel?: boolean | null,
    mediaId?: string | null,
    startDate?: string | null,
    userId?: string | null
  },
): string {
  const section = 'audio_analytics'
  return isLabel
    ? insightsLabelUrl({
      albumId,
      artistId,
      endDate,
      mediaId,
      section,
      startDate,
      userId,
    })
    : insightsUserUrl({
      albumId,
      artistId,
      endDate,
      mediaId,
      section,
      startDate,
    })
}

export function royaltyCenterSectionUrl (section?: string | null): string {
  return section ? `/d2/royalty/${section}` : '/d2/royalty'
}

export function royaltyCenterDefaultStatementsUrl (): string {
  const currentMonth = DateTime.local().startOf('month')
    .toISODate()
  const lastMonth = DateTime.local().minus({ months: 1 })
    .startOf('month')
    .toISODate()
  const twoMonthsBack = DateTime.local().minus({ months: 2 })
    .startOf('month')
    .toISODate()

  return buildUri(royaltyCenterSectionUrl('balance'), {
    filterUrlOptions: {
      dates: [currentMonth, lastMonth, twoMonthsBack],
    },
  })
}

export function payeeDetailsSectionUrl (tabName: string, id: string): string {
  return `/d2/royalty/payees/${id}/${tabName}`
}

export function royaltyCenterLabelEarningsUrl (
  {
    albumId,
    artistId,
    mediaId,
    userId,
  }: {
    albumId?: string | null,
    artistId?: string | null,
    mediaId?: string | null,
    userId?: string | null
  },
): string {
  const path = makePath(
    userId && `/users/${userId}`,
    artistId && `/artists/${artistId}`,
    (mediaId && `/medias/${mediaId}`)
    || (albumId && `/albums/${albumId}`),
  )
  return `${royaltyCenterSectionUrl('label_earnings')}${path}`
}

export function royaltyCenterOverviewUrl (
  {
    albumId,
    artistId,
    mediaId,
  }: {
    albumId?: string | null,
    artistId?: string | null,
    mediaId?: string | null
  },
): string {
  const path = makePath(
    artistId && `/artists/${artistId}`,
    mediaId && `/medias/${mediaId}`,
    albumId && `/albums/${albumId}`,
  )
  return `${royaltyCenterSectionUrl('overview')}${path}`
}

type RoyaltyCenterPaymentCreationUrl = {
  payeeInviteId?: string | null,
  section?: string | null
}

export function royaltyCenterPaymentSectionUrl (options?: RoyaltyCenterPaymentCreationUrl | null): string {
  const {
    payeeInviteId,
    section,
  } = d(options)

  const queryString = payeeInviteId
    ? `?${qs.stringify({ payee_invite: payeeInviteId })}`
    : ''

  return royaltyCenterSectionUrl(`payments/new/${section ?? 'create'}${queryString}`)
}

type RoyaltyCenterPayeeSectionUrl = {
  payeeInviteId: string,
  section?: string | null,
  sharedAssetStatus?: string | null
}

export function royaltyCenterPayeeSectionUrl (
  {
    payeeInviteId,
    section,
    sharedAssetStatus,
  }: RoyaltyCenterPayeeSectionUrl,
): string {
  const params = sharedAssetStatus && `?shared_asset_status=${sharedAssetStatus}`
  return `${royaltyCenterSectionUrl('payees')}/${payeeInviteId}/${section ?? 'info'}${params ?? ''}`
}

export function royaltyCenterTipaltiSignupUrl (options?: any): string {
  const {
    redirect = royaltyCenterSectionUrl('invites'),
  } = d(options)

  return `/d2/tipalti/signup?${qs.stringify({ redirect })}`
}

export function salesTrendsLabelUrl (
  {
    albumId,
    artistId,
    endDate,
    mediaId,
    startDate,
    userId,
  }: {
    albumId?: string | null,
    artistId?: string | null,
    endDate?: string | null,
    mediaId?: string | null,
    startDate?: string | null,
    userId?: string | null
  },
): string {
  const path = makePath(
    userId && `/users/${userId}`,
    artistId && `/artists/${artistId}`,
    albumId && `/albums/${albumId}`,
    mediaId && `/medias/${mediaId}`,
  )
  let url = `${royaltyCenterSectionUrl('sales_trends')}${path}`
  url = endDate ? `${url}?end_date=${endDate}` : url
  url = startDate ? `${url}&start_date=${startDate}` : url
  return url
}

export function salesTrendsUserUrl (
  {
    albumId,
    artistId,
    endDate,
    mediaId,
    startDate,
  }: {
    albumId?: string | null,
    artistId?: string | null,
    endDate?: string | null,
    mediaId?: string | null,
    startDate?: string | null
  },
): string {
  const path = makePath(
    artistId && `/artists/${artistId}`,
    albumId && `/albums/${albumId}`,
    mediaId && `/medias/${mediaId}`,
  )
  let url = `${royaltyCenterSectionUrl('sales_trends')}${path}`
  url = endDate ? `${url}?end_date=${endDate}` : url
  url = startDate ? `${url}&start_date=${startDate}` : url
  return url
}

// This will take a path and remove the id from the path and replace it with :id
// Used for tracking purposes
export function urlIdFormat (path: string): string {
  return replace(path, /\/(\d+)/, '/:id')
}

// This will take a path and remove the queryParams as well as replace ids
// Used for tracking purposes on google analytics
export function urlQueryParamIdFormat (path: string): string {
  const replacedPath = replace(path, /\?(\w+)=(\d+)/, '')
  return urlIdFormat(replacedPath)
}
