import {
  Suspense,
  memo,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { isBoolean } from 'lodash-es'
import { useCheckFeatures } from 'd2/components/CheckFeature'
import NotFoundScreen from 'd2/loadables/NotFoundScreen'
import PartialLayout from './PartialLayout'
import Suspender from 'react-suspender'
import isTestEnv from 'd2/utils/isTestEnv'

const D2DevLayout = memo<EO>(() => {
  const [isMounted, setIsMounted] = useState<boolean>(false)
  useEffect(() => {
    setIsMounted(true)
    return () => {
      setIsMounted(false)
    }
  }, [])
  const [hasFeature, querySwitch] = useCheckFeatures(['d2_dev'], { skip: !isMounted })

  const renderRoute = useMemo(
    () => {
      if (isTestEnv) {
        return true
      }

      return hasFeature
    },
    [hasFeature],
  )

  return (
    <Suspense fallback={querySwitch}>
      { isBoolean(renderRoute)
        ? renderRoute
          ? <PartialLayout />
          : <NotFoundScreen />
        : <>
          { /* While query is fetching, assume the user may have the feature, and begin concurrent rendering of the screen while suspending */ }
          { /* If this is a common pattern, consider making a reusable component to encapsulate this logic */ }
          <Suspender />
          <PartialLayout />
        </> }
    </Suspense>
  )
})

D2DevLayout.displayName = 'D2DevLayout'

export default D2DevLayout
